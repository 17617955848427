.skipped {
  background-color: lightskyblue !important;
}

.answered {
  background-color: #1876d1 !important;
}

.notAnswered {
  background-color: white !important;
}

.active {
  background-color: #f18b00 !important
}
.question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageBlock {
  height: 350px;
  overflow: hidden;
  width: 350px;
}

.image {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.question h4 {
  font-size: 2em;
}

.qBlock {
  background-color: #fff;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  gap: 0.5em;
  margin-top: 1em;
  width: 100%;
}

.answer {
  width: 100%;
  min-height: 61px;
}

.answer button {
  width: 100%;
}

@media (min-width: 1024px) {
  .qBlock {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, minmax(61px, 1fr));
  }
}
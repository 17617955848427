.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  position: absolute;
  text-transform: uppercase;
  padding: 0;
}

.list li {
  margin: .5em 0;
}

.list li a {
  text-decoration: none;
}

.button{
  width: 100%;
}
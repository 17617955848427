.wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.cardWrapper {
  padding: 2em 0;
}

.card {
  width: 100%;
}

.cardWrapper:first-child {
  padding-left: 0;
}

.cardWrapper:last-child {
  padding-right: 0;
}

.avatarBlock {
  padding: 0.5em 0;
  display: flex;
  justify-content: center;
}

.person {
  text-align: center;
}

.person p {
  color: lightgray;
}

@media (min-width: 600px) {
  .wrapper {
    justify-content: space-between;
  }
  .cardWrapper {
    padding: 0 20px;
    flex-basis: 300px;
  }
}

@media (min-width: 1024px) {
  .cardWrapper {
    padding: 0 20px;
    flex: 0 0 33.3333%;
  }
}
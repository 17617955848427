header {
  background-color: transparent;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em 0;
}

.header a {
  text-decoration: none;
}

section {
  padding: 48px 0 48px;
}

section.hero {
  background-color: #c5e5f6;
  height: 100vh;
  left: 0;
  position: relative;
  top: -70px;
  width: 100%;
  z-index: -1;
}

section.hero>div {
  height: 100%;
}

.blockHero {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: inherit;
}

.blockHero .text h1 {
  font-size: 5em;
}

.blockHero .text h3 {
  font-size: 1.725em;
}

.blockHero .imageBlock {
  display: none;
}

.cardSection {
  padding: 24px 0 48px;
}

.cards {
  display: grid;
  gap: 2em;
}

footer > a {
  text-decoration: none;
}

@media (min-width: 1024px) {
  section.hero {
    padding-top: 4.375em;
  }

  .blockHero .text {
    padding-right: 40px;
    flex: 0 1 50%;
  }

  .blockHero .text h1 {
    font-size: 6em;
    margin: 0;
  }

  .blockHero .text h3 {
    font-size: 1.725em;
  }

  .blockHero .imageBlock {
    display: block;
    height: 400px;
    position: relative;
    flex: 0 0 50%;
  }

  .blockHero .imageBlock svg {
    object-fit: contain;
  }

  .cards {
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}